'use strict';

window.ranger = window.ranger || {};

/**
 * Handles the Home and Feed UI.
 */
ranger.QuizBuilder = class {

  /**
   * Initializes the quizBuilder
   * @constructor
   */
  constructor() {
    /* quiz elements */
    this.questionType = $('#coachQuestionType');
    this.question = $('#coachQuestion');
    this.explanation = $('#coachReason');
    this.quizMcAnswers = $('quizQuestionAnswers');
    this.quizTitle = $('#quizTitle');
    this.quizStatus = $('#quizPublished');
    this.quizCategorySelect = $('#quizCategorySelect');

    $(document).ready(() => {
      this.quizTitle.blur(() => this.quizFeedUpdate());
      this.quizStatus.change(() => this.quizFeedUpdate());
      this.quizCategorySelect.change(() => this.quizFeedUpdate());
    });
  }


quizFeedUpdate() {
  var feedData = {
    title: $('#quizTitle').val(),
    status: $('#quizPublished').prop('checked'),
    author: {
      uid: firebase.auth().currentUser.uid,
      name: firebase.auth().currentUser.displayName
    },
    category: $('#quizCategorySelect').val()
  }

  firebase.database().ref(`/quizFeed/${firebase.auth().currentUser.uid}/${window.postKey}`).update(feedData);
}

}

ranger.quizBuilder = new ranger.QuizBuilder();
